import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Cookies } from "react-cookie-consent";
import Logo from "@components/layout/header/main/Logo";
import MainNav from "@components/layout/header/main/MainNav";
import { useScroll } from "@modules/hooks/useScroll";
import { useDomain } from "@modules/hooks/useDomain";
import React, { useEffect, useState } from "react";
import { getValueByDomain } from "@modules/utils/getValuewithDomain";
import LanguageMap from "@components/layout/header/main/LanguageMap";
import Link from "next/link";
import Image from "next/image";
import { useTablet } from "@modules/hooks/useMedia";
import { useAtom } from "jotai";
import { mainPageVer2Atom, navbarSearchboxAtom, visibleBigBannerAtom } from "@store/global";
import SearchBtnIcon from "@public/images/common/ic_search_ou_b_wh.svg";
import MyModal from "@components/layout/header/main/MyModal";
import IcCancelOu from "@public/images/common/ic_cancel_ou_b_g90.svg";
import { cancelCheckout } from "@modules/api/Checkout";
import { changeTbMetaByCrm } from "@modules/utils/Adsource";
import { IsTripbtozApp } from "@modules/utils/TripbtozApp";
import Popup from "@components/common/popup";
import { Pledge } from "@components/pledge";
import { isMobile } from "react-device-detect";
export default function MainHeader({
  className,
  isShadow,
  isLangPopup,
  setIsLangPopup,
  setIsLogout,
  openSearchBar,
  isRelative
}) {
  const {
    t
  } = useTranslation("common");
  const router = useRouter();
  const scrollY = useScroll();
  const isTTBB = useDomain();
  const isTablet = useTablet();
  const {
    locale
  } = useRouter();
  const [token, setToken] = useState(null);
  const [tokenType, setTokenType] = useState(null);
  const [mainPageVer2, setMainPageVer2] = useAtom(mainPageVer2Atom); // 신규 메인페이지 분기
  const [isBigBannerVisible] = useAtom(visibleBigBannerAtom); // BigBanner가 뷰포트에 보이는지 여부
  const [navbarSearchBoxClick, setNavbarSearchBoxClick] = useAtom(navbarSearchboxAtom); //navbar에 있는 서치박스 클릭 여부
  const [isMyMouseOver, setIsMyMouseOver] = useState(false);
  const [securityPopup, setSecurityPopup] = useState(false); // 정보 보안 & 보호 팝업
  const [infoCard, setInfoCard] = useState([]);
  const [animationIndex, setAnimationIndex] = useState(0);
  const [animation, setAnimation] = useState("flipIn"); // 애니메이션 상태
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (!isTTBB) {
      setMainPageVer2(true);
      setIsLoaded(true);
    }
  }, []);
  useEffect(() => {
    const access_token = Cookies.get("access_token");
    const token_type = Cookies.get("token_type");
    setToken(access_token);
    setTokenType(token_type);
    const cards = [{
      icon: "/images/common/ic_security_ou_multi.png",
      infoText: "pledge.infocard.security.infotext",
      link: "/pledge?section=0"
    }, {
      icon: "/images/common/ic_safety payment_ou_multi.png",
      infoText: "pledge.infocard.payment.infotext",
      link: "/pledge?section=1"
    }, {
      icon: "/images/common/ic_profit_ou_multi.png",
      infoText: "pledge.infocard.profit.infotext",
      link: "/pledge?section=2"
    }, {
      icon: "/images/common/ic_cs_ou_multi.png",
      infoText: "pledge.infocard.cs.infotext",
      link: "/pledge?section=3"
    }];
    setInfoCard(cards);
  }, [locale]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setAnimation("flipOut"); // flipOut 애니메이션 적용
      setTimeout(() => {
        setAnimationIndex(prevIndex => (prevIndex + 1) % infoCard.length);
        setAnimation("flipIn"); // 새 인덱스 적용 후 flipIn 애니메이션으로 변경
      }, 600); // 애니메이션 지속 시간
    }, 2500); // 다음 문장으로 넘어가는 시간 간격

    return () => clearInterval(intervalId);
  }, [infoCard]);
  const LogoutHandler = () => {
    setIsLogout(true);
  };
  return <>
      {!mainPageVer2 ? <header className={`${isRelative ? "relative" : "sticky"}
                  left-0 top-0 z-[13] w-full bg-white ${className}
                  ${scrollY > 0 && isShadow && !openSearchBar ? "shadow-[0_4px_18px_rgba(0,0,0,0.05)]" : ""}`}>
          <nav>
            <ul className="utilMenu flex h-[40px] items-center justify-end pr-[78px] tablet:hidden">
              {!token && <>
                  {!isTTBB && infoCard.length > 0 ? <>
                      <li className={`SB_14_100_Regular mx-[14px] flex flex-row items-center gap-1 text-Gray-800 ${animation === "flipIn" ? "animate-flipIn" : "animate-flipOut"}`}>
                        <Image src={infoCard[animationIndex].icon} alt="securityIcon" width={18} height={18} />
                        <div onClick={() => {
                  setSecurityPopup(true);
                }} className="cursor-pointer">
                          {infoCard[animationIndex].infoText}
                        </div>
                      </li>
                      <div className="h-[14px] w-[1px] bg-Gray-100"></div>
                    </> : <></>}
                  <li className="SB_14_100_Regular mx-3 block text-Gray-600">
                    <Link href={`/signin${router.asPath === "/" ? "" : "?returnUrl=" + encodeURIComponent(router.asPath)}`}>
                      {t("header.btn.login")}
                    </Link>
                  </li>
                  <li className="SB_14_100_Regular mx-3 block text-Gray-600">
                    <Link href="/signup">{t("header.btn.signup")}</Link>
                  </li>
                  <li className="SB_14_100_Regular mx-3 block text-Gray-600">
                    <Link href="/reservation-confirm">{t("header.btn.reserve.confirm")}</Link>
                  </li>
                </>}
              {token && <div onClick={LogoutHandler} className="SB_14_100_Regular mx-3 block cursor-pointer text-Gray-600">
                  {t("header.btn.logout")}
                </div>}
              <li className="SB_14_100_Regular mx-3 block text-Gray-600">
                <Link href="/cmcenter/notice">{t("header.customer.center")}</Link>
              </li>
              {!isTTBB && <LanguageMap locale={router.locale} setIsLangPopup={setIsLangPopup} />}
            </ul>
          </nav>
          <div className={`header flex h-[96px] items-center justify-between pl-[80px] pr-[56px] transition-all duration-[0.3s] 
                    ${getValueByDomain("tablet:pl-[24px] tablet:pr-[24px]", "tablet:px-4")} tablet:h-[56px] mobile:pr-0`}>
            <Logo />
            <div className={`hMenu ${!isTTBB ? "tablet:hidden" : "flex space-x-0.5 tablet:space-x-4 mobile:space-x-3.5"} `}>
              <MainNav type="home" />
              {isTTBB && <>
                  <MainNav type="flight" />
                  <MainNav type="rent" />
                </>}
              <MainNav token={token} type="my" />
            </div>
          </div>
        </header> : <header className={`${isRelative ? "relative" : "sticky"} left-0 top-0 z-20 w-full bg-white ${className} ${scrollY > 0 && isShadow && !openSearchBar && !navbarSearchBoxClick ? "shadow-[0_4px_18px_rgba(0,0,0,0.05)]" : ""}`}>
          {isTTBB || isTablet ? <>
              <nav>
                <ul className="utilMenu flex h-[40px] items-center justify-end pr-[78px] tablet:hidden">
                  {!token && <>
                      <li className="SB_14_100_Regular mx-3 block text-Gray-600">
                        <Link href={`/signin${router.asPath === "/" ? "" : "?returnUrl=" + encodeURIComponent(router.asPath)}`}>
                          {t("header.btn.login")}
                        </Link>
                      </li>
                      <li className="SB_14_100_Regular mx-3 block text-Gray-600">
                        <Link href="/signup">{t("header.btn.signup")}</Link>
                      </li>
                      <li className="SB_14_100_Regular mx-3 block text-Gray-600">
                        <Link href="/reservation-confirm">{t("header.btn.reserve.confirm")}</Link>
                      </li>
                    </>}
                  {token && <div onClick={LogoutHandler} className="SB_14_100_Regular mx-3 block cursor-pointer text-Gray-600">
                      {t("header.btn.logout")}
                    </div>}
                  <li className="SB_14_100_Regular mx-3 block text-Gray-600">
                    <Link href="/cmcenter/notice">{t("header.customer.center")}</Link>
                  </li>
                  {!isTTBB && <LanguageMap locale={router.locale} setIsLangPopup={setIsLangPopup} />}
                </ul>
              </nav>
              <div className={`header flex h-[96px] items-center justify-between pl-[80px] pr-[56px] transition-all duration-[0.3s] 
                    ${getValueByDomain("tablet:pl-[24px] tablet:pr-[24px]", "tablet:px-4")} tablet:h-[56px] mobile:pr-0`}>
                {(router.pathname === "/" || router.asPath.includes("/signin") || router.pathname === "/mypage") && <Logo />}
                {scrollY > 238 && <Link href="/search/mobile" className="mx-4 flex h-[34px] flex-grow flex-row gap-1 rounded-[50px] bg-Bg-LightGray px-3 py-1.5">
                    <Image src="/images/common/ic_search_ou_b_g60.png" alt="searchIcon" unoptimized={true} loading={"eager"} width={20} height={20} className="flex-shrink-0" />
                    <span className="SB_14_160_Regular text-Gray-300">
                      {t("searchbar.search.placeholder")}
                    </span>
                  </Link>}
                <div className={`hMenu ${!isTTBB ? "tablet:hidden" : "flex space-x-0.5 tablet:space-x-4 mobile:space-x-3.5"} `}>
                  <MainNav type="home" />
                  {isTTBB && <>
                      <MainNav type="flight" />
                      <MainNav type="rent" />
                    </>}
                  <MainNav token={token} type="my" />
                </div>
                <div className="mr-2.5 hidden cursor-pointer tablet:block" onClick={() => {
            if (token) {
              return router.push("/mypage");
            } else {
              return router.push(`/signin?returnUrl=${encodeURIComponent("/mypage")}`);
            }
          }}>
                  <Image src="/images/common/ic_my_ou_b_g90.svg" alt="myPageIcon" width={24} height={24} />
                </div>
              </div>
            </> : <>
              <nav className="mx-auto flex h-[64px] max-w-[1180px] flex-row items-center justify-between">
                <div className="ml-0 flex flex-row items-center gap-8 tablet:ml-4">
                  <Logo />
                  {!isBigBannerVisible && !navbarSearchBoxClick && router.pathname === "/" && isLoaded && <div className={`
                          flex h-[48px] cursor-pointer flex-row items-center justify-between gap-4 rounded-[100px] bg-Bg-LightGray p-1 pl-6 ${locale === "ko-KR" ? "w-[360px]" : "w-[250px]"}
                        `} onClick={() => {
              setNavbarSearchBoxClick(true);
            }}>
                        <span className="B_16_100_Regular text-Gray-500">
                          {t("searchbar.search.placeholder")}
                        </span>
                        <div className="flex items-center justify-center rounded-full bg-Gray-900 p-[11px]">
                          <SearchBtnIcon w={18} h={18} />
                        </div>
                      </div>}
                </div>
                <ul className="relative flex items-center text-Gray-600">
                  {!token && <>
                      {infoCard.length > 0 ? <>
                          <li className={`SB_14_100_Regular mx-[14px] flex flex-row items-center gap-1 text-Gray-800 ${animation === "flipIn" ? "animate-flipIn" : "animate-flipOut"}`}>
                            <Image src={infoCard[animationIndex].icon} alt="securityIcon" width={18} height={18} />
                            <div onClick={() => {
                    setSecurityPopup(true);
                  }} className="cursor-pointer">
                              {t(infoCard[animationIndex].infoText)}
                            </div>
                          </li>
                          <div className="h-[14px] w-[1px] bg-Gray-100"></div>
                        </> : <></>}
                      <li className="SB_14_100_Regular mx-3">
                        <Link href={`/signin${router.asPath === "/" ? "" : "?returnUrl=" + encodeURIComponent(router.asPath)}`}>
                          {t("header.btn.login")}
                        </Link>
                      </li>
                      <li className="SB_14_100_Regular mx-3">
                        <Link href="/signup">{t("header.btn.signup")}</Link>
                      </li>
                      <li className="SB_14_100_Regular mx-3">
                        <Link href="/reservation-confirm">{t("header.btn.reserve.confirm")}</Link>
                      </li>
                    </>}
                  <li className="SB_14_100_Regular mx-3">
                    <Link href="/cmcenter/notice">{t("header.customer.center")}</Link>
                  </li>
                  {!isTTBB && <LanguageMap locale={router.locale} setIsLangPopup={setIsLangPopup} />}
                  {token && !!tokenType && router.pathname !== "/mypage" && <>
                      <div onMouseEnter={() => {
                setIsMyMouseOver(true);
              }} onMouseLeave={() => {
                setIsMyMouseOver(false);
              }} onClick={() => {
                router.push("/mypage");
              }} className="SB_14_100_Regular mx-3 flex h-10 cursor-pointer flex-row items-center justify-center gap-1 p-3 hover:rounded-[8px] hover:bg-Bg-TintWhite">
                        <Image src={"/images/common/ic_my_fi_g90.svg"} alt="myPageIcon" width={18} height={18} className="h-[18px] w-[18px]" />
                        <span>{t("header.btn.my")}</span>
                      </div>
                      {/*마이페이지 모달*/}
                      <MyModal token={token} isMyMouseOver={isMyMouseOver} />
                    </>}
                </ul>
              </nav>
            </>}
        </header>}

      <Popup isOpen={securityPopup} className="!z-20 flex max-h-[810px] max-w-[516px]" innerClass="rounded-[20px] flex-col scrollbar-hide !z-20" customDim={<div className="fixed inset-0 z-10 h-full w-full bg-black opacity-80" />} onClose={() => setSecurityPopup(false)}>
        {closePopup => <>
            {/*닫기 버튼*/}
            <div className="sticky top-0 z-20 flex w-full cursor-pointer items-center justify-center bg-white px-8 py-6">
              <div className="absolute left-8">
                <IcCancelOu onClick={closePopup} />
              </div>
              <h5 className="H5_20_100_Bold text-Gray-900">{t("pledge.page.title")}</h5>
            </div>

            <Pledge popupMode={true} />
          </>}
      </Popup>
    </>;
}