import Footer from "./footer";
import HomeBar from "./HomeBar";
import { useTranslation } from "next-i18next";
import TopBtn from "./TopBtn";
import MainHeader from "./header/MainHeader";
import SignupHeader from "./header/SignupHeader";
import LoginHeader from "./header/LoginHeader";
import { Contains } from "@constants/contains";
import MypageHeader from "./header/MypageHeader";
import MypageMainHeader from "./header/MypageMainHeader";
import CheckOutHeader from "./header/CheckOutHeader";
import MdHeader from "./header/MdHeader";
import React, { useEffect, useRef, useState } from "react";
import { tokenRefresh } from "@modules/api/Auth";
import { useRouter } from "next/router";
import { Cookies } from "react-cookie-consent";
import { clearToken, getDomain } from "@modules/utils/AccessToken";
import LogoutPopup from "../LogoutPopup";
import Toast from "../Toast";
import ProfileHeader from "./header/ProfileHeader";
import { COOKIE_KEY, getStorageInfo, setTbMeta, STORAGE_KEY } from "@modules/utils/Adsource";
import { IsTripbtozApp, IsTTBBApp } from "@modules/utils/TripbtozApp";
import VerifyHeader from "@components/layout/header/VerifyHeader";
import ChannelTalk from "@components/script/ChannelTalk";
import { useDomain } from "@modules/hooks/useDomain";
import { useAtom } from "jotai";
import { firstEntryCheckAtom, isSrookpayAtom, mainPageVer2Atom, videoOpenAtom } from "@store/global";
import { isMobile } from "react-device-detect";
import KayakHeader from "@components/layout/header/KayakHeader";
import { getCookie, setCookie } from "cookies-next";
import dayjs from "dayjs";
import { datadogRum } from "@datadog/browser-rum";
import { getMyProfile } from "@modules/api/Crew";
export default function Layout({
  children,
  title,
  headerType,
  wrapClassName,
  openSearchBar,
  isLangPopup,
  setIsLangPopup,
  hideTopBtn,
  isHomeBarFixed,
  openSettingLayer,
  setIsWithdrawPopup,
  setOpenSettingLayer,
  mode,
  isApp,
  onOpenSharePopupHandler
}) {
  const {
    t
  } = useTranslation("common");
  const {
    locale,
    pathname,
    query
  } = useRouter();
  const router = useRouter();
  const isTTBBApp = IsTTBBApp();
  const isTTBB = useDomain();
  const [isLogout, setIsLogout] = useState(false);
  const [toastState, setToastState] = useState(false);
  const [, setEntryCheck] = useAtom(firstEntryCheckAtom);
  const [isVideoOpen, setIsVideoOpen] = useAtom(videoOpenAtom);
  const [channelTalkFlag, setChannelTalkFlag] = useState(false);
  const [isSrookpay, setIsSrookpay] = useAtom(isSrookpayAtom);
  const [mainPageVer2, setMainPageVer2] = useAtom(mainPageVer2Atom); // 신규 메인페이지 분기

  useEffect(() => {
    if (!isTTBB) {
      setMainPageVer2(true);
    }
  }, []);
  const makeNationCode = async () => {
    // const response = await fetch('https://api.ip.pe.kr/json/');
    let response;
    try {
      response = await fetch("https://geolocation-db.com/json/");
    } catch (e) {
      console.log("nationCodeAPI error: ", e);
    }
    return await response.json();
  };
  const setNationCode = async () => {
    // const countryCode = await makeNationCode();
    // setCookie("country_code", countryCode?.country_code, {
    //   maxAge: 60 * 60 * 24,
    // });
    setCookie("country_code", locale?.split("-")[1], {
      maxAge: 60 * 60 * 24
    });
  };
  useEffect(() => {
    setNationCode();
    if (Cookies.get("logout") === "Y") {
      setToastState(true);
      Cookies.set("logout", "", {
        expires: -1,
        domain: getDomain()
      });
    }
    const access_token = Cookies.get("access_token");
    const refresh_token = localStorage.getItem("refresh_token");
    if (!access_token && refresh_token) {
      tokenRefresh(locale, {
        refresh_token: refresh_token
      }).then(data => {
        Cookies.set("token_type", data.body.token_type, {
          expires: data.body.expires_in / 60 / 60 / 24,
          domain: getDomain()
        });
        Cookies.set("access_token", data.body.access_token, {
          expires: data.body.expires_in / 60 / 60 / 24,
          domain: getDomain()
        });
        Cookies.set("refresh_token", data.body.refresh_token, {
          expires: data.body.refresh_token_expires_in / 60 / 60 / 24,
          domain: getDomain()
        });
        if (refresh_token) {
          localStorage.setItem("refresh_token", data.body.refresh_token);
        }
        getMyProfile(locale).then(profile => {
          Cookies.set("user_id", profile?.body.item.id, {
            expires: data.body.expires_in / 60 / 60 / 24,
            domain: getDomain()
          });
          datadogRum.setUser({
            id: profile?.body?.item?.id
          });
          router.reload();
        });
      }).catch(() => {
        clearToken();
        // if(IsTripbtozApp()){
        //   try{
        //     connectSignBridge();
        //   }catch(e){;}
        // }
        router.push("/");
      });
    }
    const resizeEvent = () => {
      if (document.activeElement.tagName == "INPUT" || document.activeElement.tagName == "TEXTAREA") {
        document.activeElement.scrollIntoView(false);
      }
    };
    setEntryCheck(true);
    window.addEventListener("resize", resizeEvent);
    return () => {
      window.removeEventListener("resize", resizeEvent);
    };
  }, []);
  useEffect(() => {
    if (typeof window !== "undefined" && !isApp && !isTTBBApp) {
      router.beforePopState(({
        url,
        as,
        options
      }) => {
        if (as !== router.asPath && isVideoOpen) {
          window.history.pushState("", "");
          router.push(router.asPath);
          setIsVideoOpen(false);
          return false;
        }
        return true;
      });
    }
    return () => {
      router.beforePopState(() => true);
    };
  }, [isVideoOpen]);
  useEffect(() => {
    if (!router.isReady) return;
    setTbMeta(router, "");
    const {
      utm_source
    } = router.query;
    if (!utm_source) {
      // https://tripbtoz.atlassian.net/browse/FRTEND-681
      // search.naver.com, www.google.com, www.google.co.kr 유입 확인
      const referrerType = document.referrer.indexOf("search.naver.com") > -1 ? "SEO_NAVER" : document.referrer.indexOf("www.google.co") > -1 ? "SEO_GOOGLE" : "";

      // referrerType이 있고, 기존 저장된 meta가 없을때만 seo meta 저장하도록 설정
      if (!!referrerType && !(getStorageInfo(STORAGE_KEY.T_META_KEY)?.meta_type || Cookies.get(COOKIE_KEY.TBMETA))) {
        const info = {
          meta_type: referrerType,
          marketing_meta: "",
          create_date: dayjs()
        };
        localStorage.removeItem(STORAGE_KEY.AD_SOURCE_EVENT_KEY);
        localStorage.setItem(STORAGE_KEY.T_META_KEY, JSON.stringify(info));
        const domain = getDomain();
        const expireTime = dayjs().add(30, "days").toDate();
        Cookies.set(COOKIE_KEY.TBMETA, referrerType, {
          expires: expireTime,
          domain: domain
        });
      }
    }
  }, [router.isReady]);
  useEffect(() => {
    if (isSrookpay && !router.pathname.includes("/hotels/") && !router.pathname.includes("/checkout/") && !router.pathname.includes("/signin/")) setIsSrookpay(null);
  }, []);
  useEffect(() => {
    // 랭킹, 플레이탭 MD의 경우 채널톡 hidden
    let flag = headerType === Contains.MD ? !(query.id === "play_intro_kr" || query.channel_talk) : isMobile ? isTTBB ? headerType !== Contains.HOTEL && headerType !== Contains.CHECKOUT : headerType !== Contains.CHECKOUT : true;
    if (headerType === Contains.RANK || (headerType === Contains.PLEDGE || headerType === Contains.MYPAGE) && (isApp || IsTripbtozApp())) {
      flag = false;
    }
    setChannelTalkFlag(flag);
    const talkEl = document.getElementById("ch-plugin");
    if (talkEl) {
      talkEl.style.setProperty("display", `${!flag ? "none" : ""}`, "important");
    }
  }, [headerType]);
  const channelTalkLoad = () => {
    return channelTalkFlag ? <ChannelTalk /> : null;
  };
  return <>
      {channelTalkLoad()}
      <div id="wrap" className={`transition-all delay-[0s] duration-[0.3s] ease-[ease]
                    ${headerType === Contains.MYPAGE || headerType === Contains.HOTEL ? "tablet:mx-auto tablet:my-0 tablet:flex tablet:min-h-full tablet:min-w-[320px] tablet:flex-col" : "mobile:mx-auto mobile:my-0 mobile:flex mobile:min-h-full mobile:min-w-[320px] mobile:flex-col"}
                    ${wrapClassName ?? ""}`}>
        {/*mobile:max-w-[420px]*/}

        {/* 상단 */}
        {(headerType === Contains.MAIN || headerType === Contains.SEARCH || headerType === Contains.HOTEL) && <MainHeader className={`${(headerType === Contains.HOTEL || headerType === Contains.CHECKOUT || headerType === Contains.SEARCH) && "tablet:hidden"}`} isShadow={headerType === Contains.MAIN || headerType === Contains.CHECKOUT} isRelative={headerType === Contains.HOTEL || headerType === Contains.SEARCH} setIsLogout={setIsLogout} openSearchBar={openSearchBar} />}
        {headerType === Contains.LOGIN && <LoginHeader openSettingLayer={openSettingLayer} setOpenSettingLayer={setOpenSettingLayer} />}
        {headerType === Contains.SIGNUP && <SignupHeader />}
        {headerType === Contains.MYPAGE_MAIN && <MypageMainHeader setIsLogout={setIsLogout} />}
        {(headerType === Contains.MYPAGE || headerType === Contains.CITY_STORY || headerType === Contains.WITHDRRAW || headerType === Contains.PLEDGE) && <MypageHeader title={title} isLangPopup={isLangPopup} setIsLangPopup={setIsLangPopup} setIsLogout={setIsLogout} mode={mode} isApp={isApp} />}
        {headerType === Contains.PROFILE && <ProfileHeader setIsWithdrawPopup={setIsWithdrawPopup} setIsLogout={setIsLogout} />}
        {headerType === Contains.CHECKOUT && <CheckOutHeader setIsLogout={setIsLogout} />}
        {headerType === Contains.MD && <MdHeader setIsLogout={setIsLogout} isApp={isApp} onOpenSharePopupHandler={onOpenSharePopupHandler} />}
        {headerType === Contains.VERIFY && <VerifyHeader title={title} isApp={isApp} onOpenSharePopupHandler={onOpenSharePopupHandler} />}
        {headerType === Contains.KAYAK && <KayakHeader />}
        {/* // 상단 */}

        {/* 컨텐츠 */}
        {children}
        {/* // 컨텐츠 */}

        {/*/!* 푸터 *!/*/}
        {(headerType === Contains.MAIN || headerType === Contains.HOTEL || headerType === Contains.MD || headerType === Contains.CITY_STORY || headerType === Contains.KAYAK || headerType === Contains.RANK) && !query?.footer && <Footer />}

        {(headerType === Contains.MYPAGE_MAIN || headerType === Contains.CHECKOUT || headerType === Contains.MYPAGE || headerType === Contains.PROFILE || headerType === Contains.WITHDRRAW || headerType === Contains.PLEDGE) && !query?.footer && <Footer className={`tablet:hidden`} />}
        {/* // 푸터 */}

        {/* 하단 네비게이션 */}
        {!isTTBBApp && !mainPageVer2 && (headerType === Contains.MAIN || headerType === Contains.LOGIN || headerType === Contains.MYPAGE_MAIN || headerType === Contains.SEARCH_MOBILE) && <HomeBar isHomeBarFixed={isHomeBarFixed} />}
        {/* // 하단 네비게이션 */}

        {!hideTopBtn && <TopBtn />}

        {isLogout && <LogoutPopup setIsLogout={setIsLogout} />}

        {toastState && <Toast toastText={t("mypage.logout.completed")} setToastState={setToastState} />}
      </div>
    </>;
}